import { gql } from "@apollo/client";
import {
  CardFragment,
  ContributorFragmentWithPosts,
} from "../../fragments/post";

export const GET_POSTS = gql`
  query GET_POSTS($last: Int) {
    posts(last: $last) {
      edges {
        node {
          id
          title
          uri
        }
      }
    }
  }
`;

export const GET_MY_POST_BY_ID = gql`
  query GET_POST_BY_ID($id: ID!) {
    post(id: $id, idType: DATABASE_ID) {
      content(format: RENDERED)
    }
  }
`;

export const GET_POST_PREVIEW = gql`
  query GET_POST($uri: ID!) {
    post(idType: URI, id: $uri) {
      id
      content(format: RENDERED)
      title(format: RENDERED)
      uri
      postId
      featuredImage {
        node {
          mediaDetails {
            sizes(include: [MEDIUM_LARGE, LARGE ]) {
              sourceUrl
              name
            }
          }
        }
      }
      categories {
        nodes {
          name
          uri
          slug
        }
      }
      author {
        node {
          avatar {
            url
          }
          id
          name
          nicename
          nickname
          slug
          uri
        }
      }
      articleOptions {
        byline
        team {
          nodes {
            ... on Category {
              id
              name
            }
          }
        }
        highlightText
        highlight
        frontPageTitle
      }
      date
      modified
      excerpt(format: RENDERED)
      comments(where: {status: "any"}) {
        nodes {
          author {
            node {
              avatar {
                default
                url
              }
              id
              name
            }
          }
          content(format: RENDERED)
          databaseId
          parentId
          parentDatabaseId
          date
          status
        }
      }
      ${ContributorFragmentWithPosts}
    }
    
  }
`;

export const GET_POST_PREVIEW_SLUG = gql`
  query GET_POST($slug: ID!) {
    post(idType: SLUG, id: $slug) {
      id
      content(format: RENDERED)
      title(format: RENDERED)
      uri
      postId
      featuredImage {
        node {
          mediaDetails {
            sizes(include: [MEDIUM_LARGE, LARGE ]) {
              sourceUrl
              name
            }
          }
        }
      }
      categories {
        nodes {
          name
          uri
          slug
        }
      }
      author {
        node {
          avatar {
            url
          }
          id
          name
          nicename
          nickname
          slug
          uri
        }
      }
      articleOptions {
        byline
        team {
          nodes {
            ... on Category {
              id
              name
            }
          }
        }
        highlightText
        highlight
        frontPageTitle
      }
      date
      modified
      excerpt(format: RENDERED)
      comments(where: {status: "any"}) {
        nodes {
          author {
            node {
              avatar {
                default
                url
              }
              id
              name
            }
          }
          content(format: RENDERED)
          databaseId
          parentId
          parentDatabaseId
          date
          status
        }
      }
      ${ContributorFragmentWithPosts}
    }
    
  }
`;

export const GET_POST_PREVIEW_RAW = gql`
  query GET_POST($uri: ID!) {
    post(idType: URI, id: $uri) {
      id
      content(format: RAW)
      title(format: RENDERED)
      uri
      postId
      featuredImage {
        node {
          mediaDetails {
            sizes(include: [MEDIUM_LARGE, LARGE ]) {
              sourceUrl
              name
            }
          }
        }
      }
      categories {
        nodes {
          name
          uri
          slug
        }
      }
      author {
        node {
          avatar {
            url
          }
          id
          name
          nicename
          nickname
          slug
          uri
        }
      }
      articleOptions {
        byline
        team {
          nodes {
            ... on Category {
              id
              name
            }
          }
        }
        highlightText
        highlight
        frontPageTitle
      }
      date
      excerpt(format: RENDERED)
      comments(where: {status: "any"}) {
        nodes {
          author {
            node {
              avatar {
                default
                url
              }
              id
              name
            }
          }
          content(format: RENDERED)
          databaseId
          parentId
          parentDatabaseId
          date
          status
        }
      }
      ${ContributorFragmentWithPosts}
    }
    
  }
`;

export const GET_POST = gql`
  query GET_POST($uri: ID!) {
    post(idType: URI, id: $uri) {
      content(format: RENDERED)
      title(format: RENDERED)
      uri
      featuredImage {
        node {
          mediaDetails {
            sizes(include: [MEDIUM_LARGE, LARGE]) {
              sourceUrl
              name
            }
          }
        }
      }
      categories {
        nodes {
          name
          uri
          slug
        }
      }
      author {
        node {
          avatar {
            url
          }
          id
          name
          nicename
          nickname
          slug
          uri
        }
      }
    }
  }
`;

export const GET_POSTS_PAGINATED = gql`
  query NewQuery($amount:Int, $after:String,$notIn: [ID]) {
    posts(first: $amount, after: $after, where: { notIn: $notIn}) {
    edges {
      cursor
      node {
       ${CardFragment}
      }
    }
  }
}
`;

export const GET_CATEGORY_POSTS_PAGINATED = gql`
  query GET_POST($id: ID!, $after: String, $notIn: [ID]) {
    category(id: $id, idType: URI) {
      id
      name
      taxonomyName
      posts(after: $after, where: { notIn: $notIn}) {
        edges {
          cursor
          node {
            ${CardFragment}
          }
        }
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query GET_USER_INFO($id: ID!) {
    user(id: $id, idType: ID) {
      id
      databaseId
      username
      name
      email
      firstName
      lastName
      roles {
        nodes {
          name
        }
      }
    }
  }
`;
